<template>
  <div>
    <validation-observer
      ref="refFileObserver"
    >
      <div class="row">

        <div class="col-12 w-100">
          <validation-provider
            #default="validationContext"
            name="file title"
            rules="required|max:191"
          >
            <b-form-group
              label="File title"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="title"
                :state="validationContext.errors.length > 0 ? false:null"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>

        <div class="w-100">
          <validation-provider
            #default="validationContext"
            name="factory"
            :rules="{required}"
          >
            <b-form-group
              label="Factory"
              label-for="factory"
              class="col-12"
            >
              <b-form-select
                v-model="factory"
                class="col-12"
                label="factory"
                :options="factoryOptions"
                :state="validationContext.errors.length > 0 ? false:null"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>

        <div class="w-100">
          <validation-provider
            #default="validationContext"
            name="inspector"
            :rules="{required}"
          >
            <b-form-group
              label="Inspector"
              label-for="rule"
              class="col-12"
            >
              <b-form-select
                v-model="inspector"
                class="col-12"
                label="factory"
                :options="inspectorOptions"
                :state="validationContext.errors.length > 0 ? false:null"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>

        <div class="col-12 w-100">
          <validation-provider
            #default="fileValidationContext"
            name="file"
            rules="ext:jpeg,jpg,png,pdf,xlsx,xls,step,stp,sldprt"
          >
            <b-form-group
              label="File"
              label-for="file"
            >
              <input
                id="file"
                name="file"
                type="file"
                :class="fileValidationContext.errors.length > 0 ? 'col-12 form-control is-invalid' : 'col-12 form-control'"
                accept=".jpeg,.jpg,.png,.pdf,.xlsx,.xls,.step,.stp,.sldprt"
                :state="fileValidationContext.errors.length > 0 ? false:null"
                @change="selectFile"
              >

              <b-form-invalid-feedback>
                {{ fileValidationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>

      </div>
    </validation-observer>

    <div
      v-if="currentFile"
      class="progress mt-1"
    >
      <div
        class="progress-bar progress-bar-info"
        role="progressbar"
        :aria-valuenow="progress"
        aria-valuemin="0"
        aria-valuemax="100"
        :style="{ width: progress + '%' }"
      >
        {{ progress }}%
      </div>

    </div>

    <div v-if="previewFile && typeof currentFile !== 'undefined' && typeof currentFile.type !== 'undefined' && allowedImagesMimes.indexOf(currentFile.type) >= 0">
      <div class="text-center">
        <img
          width="100px"
          height="100px"
          class="preview my-3 img-fluid"
          :src="previewFile"
          alt=""
        >
      </div>
    </div>

    <div v-else-if="typeof currentFile !== 'undefined' && typeof currentFile.type !== 'undefined'">
      <div class="text-center mt-1">
        <feather-icon
          icon="FileIcon"
          width="100px"
          height="100px"
        />
      </div>
    </div>

    <b-list-group class="mt-1">
      <b-list-group-item
        v-for="(file, index) in filesList"
        :key="index"
        class="d-flex justify-content-between align-items-center"
      >
        <a
          target="_blank"
          class="d-block w-100"
          :href="file.file"
        >
          <b-avatar
            :src="file.file"
            class="mr-1 float-left"
          />
          <span class="mr-auto float-left">{{ file.title }}</span>
        </a>
        <b-badge
          class="delete-icon"
          variant="secondary"
          pill
          @click="() => deleteFile(file)"
        >
          <feather-icon
            v-b-tooltip="'Delete this file'"
            icon="XIcon"
          />
        </b-badge>
      </b-list-group-item>
    </b-list-group>

    <div class="modal-footer">

      <button
        type="button"
        class="btn btn-primary"
        @click="upload"
      >Upload</button>
      <button
        type="button"
        class="btn btn-secondary"
        data-dismiss="modal"
        @click="$emit('close', 1)"
      >
        Close
      </button>
    </div>

  </div>
</template>

<script>
import Moment from 'moment'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, alphaNum, email, url, mimes, integer, max, min, regex,
} from '@validations'
import ProgressiveUploader from '@/libs/progressive-uploader'

export default {
  name: 'UploadImage',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      factory: null,
      inspector: null,
      factoryOptions: [
        { text: 'Please select an option', value: null },
        { text: 'ZSF', value: 'zsf' },
        { text: 'Sandris', value: 'sandris' },
        { text: 'Walter', value: 'walter' },
      ],
      inspectorOptions: [
        { text: 'Please select an option', value: null },
        { text: 'Chao', value: 'chao' },
        { text: 'ProQC', value: 'proqc' },
        { text: 'Qima', value: 'qima' },
        { text: 'Sofeast', value: 'sofeast' },
      ],
      moment: Moment,
      currentFile: undefined,
      previewFile: undefined,
      progress: 0,
      filesList: [],
      title: '',
      allowedImagesMimes: [
        'image/jpeg',
        'image/jpg',
        'image/png',
      ],
      allowedMimes: [
        'image/jpeg',
        'image/jpg',
        'image/png',
        'application/pdf',
      ],
      max,
      min,
      required,
      alphaNum,
      email,
      url,
      mimes,
      integer,
      regex,
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      return this.$http.get(this.$resources.calendar.files.files, {
        params: {
          timestamp: this.date,
        },
      }).then(response => {
        this.filesList = response.data.data.files
      })
    },
    deleteFile(file) {
      this.$swal({
        title: `Delete ${file.title} file?`,
        text: `Would you like to block this ${file.title} file?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Yes',
      }).then(result => { // <--
        if (result.value) { // <-- if confirmed
          this.$http.delete(this.$resources.calendar.files.destroy.replace(':id', file.id))
            .then(() => {
              this.load().then(() => {
                if (this.filesList.length <= 0) {
                  this.$emit('delete', file.day_date)
                }
              })
            })
        }
      })
    },
    selectFile(e) {
      // eslint-disable-next-line prefer-destructuring
      this.currentFile = e.target.files[0]
      if (typeof this.currentFile === 'undefined' || typeof this.currentFile.type === 'undefined') { return }

      if (this.allowedMimes.indexOf(this.currentFile.type) >= 0) {
        this.previewFile = URL.createObjectURL(this.currentFile)
        this.progress = 0
      }
    },
    upload() {
      this.$refs.refFileObserver.validate()
        .then(success => {
          if (success) {
            if (!this.currentFile) {
              // TODO: notify
              return
            }

            this.progress = 0

            const formData = new FormData()

            formData.append('file', this.currentFile)
            formData.append('date', this.date)
            formData.append('title', this.title)
            formData.append('factory', this.factory)
            formData.append('inspector', this.inspector);

            (new ProgressiveUploader(this.$resources.calendar.files.store)).upload(formData, event => {
              this.progress = Math.round((100 * event.loaded) / event.total)
            }).then(response => {
              this.currentFile = undefined

              this.previewFile = undefined

              this.title = ''

              this.factory = ''

              this.inspector = ''

              this.$refs.refFileObserver.reset()

              this.$emit('add', response.data.data.event)
              this.$emit('close')

              this.load()
            }).catch(err => {
              this.$refs.refFileObserver.setErrors(err.response.data)

              this.progress = 0
              this.currentFile = undefined
            })
          }
        })
    },
  },
}
</script>

<style scoped>
.delete-icon {
  cursor: pointer;
}
</style>
