<template>
  <div class="pt-1 full-calendar">
    <b-card>
      <FullCalendar
        ref="fullCalendar"
        :options="filesCalendarOptions"
      />
    </b-card>
    <div>
      <b-modal
        id="event-modal"
        ref="dayModal"
        :title="modal.title"
        hide-footer
      >
        <upload-file
          :date="modal.date"
          @close="close"
          @delete="remove"
          @add="add"
        />
      </b-modal>
    </div>
    <div>
      <b-modal
        id="modal-lg"
        ref="search_modal"
        size="xl"
        title="search"
      >
        <b-row>
          <b-col
            cols="12"
            sm="2"
          >
            <b-input-group v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                id="factories-class"
                v-model="factoryOptionSelected"
                :aria-describedby="ariaDescribedby"
                name="factories"
                class="factories-class mt-1"
                @change="search"
              >
                <ul class="list-unstyled search-options">
                  <li>
                    <b-form-checkbox
                      value="zsf"
                      class="pl-2-5"
                    >
                      ZSF
                    </b-form-checkbox>
                  </li>
                  <li>
                    <b-form-checkbox
                      value="sandris"
                      class="pl-2-5 mt-1"
                    >
                      Sandris
                    </b-form-checkbox>
                  </li>
                  <li>
                    <b-form-checkbox
                      value="walter"
                      class="pl-2-5 mt-1"
                    >
                      Walter
                    </b-form-checkbox>
                  </li>
                </ul>
              </b-form-checkbox-group>
            </b-input-group>
          </b-col>
          <b-col
            sm="10"
            cols="12"
          >
            <b-input-group class="">
              <b-input
                v-model="searchKeyword"
                type="text"
                placeholder="please enter as search keyword"
                @keydown.enter="search"
              />
              <b-row class="col-12 mt-1">
                <b-col
                  v-if="searchLoading"
                  class="d-flex justify-content-center mt-2"
                >
                  <b-spinner label="Loading..." />
                </b-col>

                <div
                  v-if="searchFiles.length <= 0"
                  class="font-weight-bold"
                >
                  No files found
                </div>

                <b-col
                  v-for="(searchFile, index) in searchFiles"
                  v-else
                  :key="index"
                  class="d-flex justify-content-center"
                  sm="3"
                  cols="12"
                >
                  <b-row class="d-flex justify-content-center mb-3 item-holder">
                    <b-col>
                      <b-row class="d-flex justify-content-center">
                        <b-img
                          v-if="imgs_ext.indexOf(searchFile.ext) >= 0"
                          v-b-tooltip="searchFile.title"
                          height="150"
                          :src="searchFile.file"
                          rounded="bottom"
                          alt="Bottom-rounded image fluid"
                        />
                        <feather-icon
                          v-else
                          v-b-tooltip="searchFile.title"
                          icon="FileIcon"
                          width="150px"
                          height="150px"
                        />
                      </b-row>
                      <b-row class="d-flex justify-content-center mt-1">
                        <h4>{{ searchFile.title }}</h4>
                      </b-row>
                      <b-row class="d-flex justify-content-center mt-1">
                        <feather-icon
                          v-b-tooltip="'Download file'"
                          class="icon-pointer mr-2"
                          icon="DownloadIcon"
                          @click="downloadFile(searchFile)"
                        />
                        <feather-icon
                          v-b-tooltip="'Open file'"
                          class="icon-pointer mr-2"
                          icon="LinkIcon"
                          @click="openFile(searchFile)"
                        />
                        <feather-icon
                          v-b-tooltip="'Delete file'"
                          class="icon-pointer mr-2"
                          icon="XIcon"
                          @click="deleteFile(searchFile, index)"
                        />
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-input-group>
          </b-col>
        </b-row>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="closeSearch"
            >
              Ok
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardText, BButton, BModal, VBTooltip,
} from 'bootstrap-vue'
import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import Moment from 'moment'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import listPlugin from '@fullcalendar/list'
import axios from 'axios'
import uploadFile from './components/uploadFile.vue'

export default {
  components: {
    FullCalendar,
    uploadFile,
  },
  BCard,
  BButton,
  BCardText,
  BModal,
  FeatherIcon,
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      searchLoading: false,
      moment: Moment,
      modal: {
        title: 'Modal Title',
      },
      searchKeyword: null,
      imgs_ext: ['jpeg', 'jpg', 'png'],
      files_ext: ['pdf', 'xlsx', 'xls', 'step', 'stp', 'sldprt'],
      searchEvents: [],
      searchFiles: [],
      filesCalendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, listPlugin],
        initialView: 'dayGridMonth',
        dayCellDidMount: this.dayCell.bind(this),
        eventClick: this.eventClick.bind(this),
        events: [],
        headerToolbar: {
          start: 'prev,next today',
          center: 'title',
          end: 'dayGridMonth,dayGridWeek,dayGridDay,searchButton',
        },
        customButtons: {
          searchButton: {
            text: 'search',
            click: this.searchForm.bind(this),
          },
        },
      },
      factoriesOptions: [
        { text: 'ZSF', value: 'zsf' },
        { text: 'Sandris', value: 'sandris' },
        { text: 'Walter', value: 'walter' },
      ],
      factoryOptionSelected: [],
      axios,
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    closeSearch() {
      this.$refs.search_modal.hide()
    },
    downloadFile(searchFile) {
      fetch(searchFile.file, { mode: 'no-cors' })
        .then(res => res.blob())
        .then(file => {
          const tempUrl = URL.createObjectURL(file)
          const aTag = document.createElement('a')

          aTag.href = tempUrl
          aTag.download = searchFile.file.replace(/^.*[\\\\/]/, '')

          document.body.appendChild(aTag)
          aTag.click()

          URL.revokeObjectURL(tempUrl)
          aTag.remove()
        })
    },
    openFile(searchFile) {
      window.open(searchFile.file)
    },
    deleteFile(searchFile, fileIndex) {
      this.$swal({
        title: `Delete ${searchFile.title} file?`,
        text: `Would you like to block this ${searchFile.title} photo?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.value) {
          this.$http.delete(this.$resources.calendar.files.destroy.replace(':id', searchFile.id)).then(() => {
            this.searchFiles.splice(fileIndex, 1)
          })
        }
      })
    },
    searchForm() {
      this.searchFiles = []
      this.searchKeyword = ''
      this.factoryOptionSelected = ['zsf']
      this.$refs.search_modal.show()
      this.search()
    },
    search() {
      this.searchFiles = []
      this.searchLoading = true

      const params = {
        keyword: this.searchKeyword,
        factory: this.factoryOptionSelected,
      }

      this.$http.get(this.$resources.calendar.files.search, {
        params,
      }).then(response => {
        this.searchFiles = response.data.data.files
        this.searchLoading = false
      })
    },
    close() {
      this.$refs.dayModal.hide()
    },
    eventClick(e) {
      this.loadDayData(e.event.start)
    },
    dayCell(cell) {
      cell.el.onclick = () => {
        this.loadDayData(cell.date)
      }
    },
    loadDayData(cellDate) {
      const date = this.moment(cellDate).format('YYYY-MM-DD', { trim: true })

      this.modal = {
        title: this.moment(cellDate).format('D - M - YYYY'),
        date,
      }

      this.$refs.dayModal.show()
    },
    load() {
      this.$http
        .get(this.$resources.calendar.files.index)
        .then(response => {
          this.filesCalendarOptions.events = response.data.data.events.map(e => ({ ...e, date: e.day_date }))
        })
    },
    add(newEvent) {
      const event = this.filesCalendarOptions.events.find(e => e.start === newEvent.start)

      if (!event) {
        this.filesCalendarOptions.events.push(newEvent)
      }
    },
    remove(eventDate) {
      const eventIndex = this.filesCalendarOptions.events
        .map(event => event.start)
        .indexOf(eventDate)
      if (eventIndex > -1) {
        this.filesCalendarOptions.events.splice(eventIndex, 1)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.icon-pointer {
  cursor: pointer;
}
.pl-2-5 {
  padding-left: 25px !important;
}
.input-group {
  &::not(.bootstrap-touchspin) {
    box-shadow: none !important;
  }
}

@media screen and (max-width: 750px) {
  .full-calendar {
    ::v-deep {
      .fc {
        .fc-header-toolbar {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          .fc-toolbar-chunk {
            grid-column: 1;
            display: flex;
            justify-content: space-between;
            .fc-toolbar-title {
              display: block;
              width: 100%;
              text-align: center;
              margin-bottom: 5px;
            }
          }
          .fc-button-group {
            margin-bottom: 5px;
            &:last-child {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
